import './App.css';
import { Masonry } from "masonic";
import { useState, useEffect, useCallback } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider } from '@mui/material';

const getAf = () => {
  let afImages = [];
  for (let index = 1; index <= 622; index++) {
    afImages.push({ img: `Images/Alla bilder från fotografen/af${index}.jpg`, title: '' })
  }
  return afImages;
}

const getBg = () => {
  let bgImages = [];
  for (let index = 1; index <= 53; index++) {
    bgImages.push({ img: `Images/Bilder från gästerna/bg${index}.jpg`, title: '' })
  }
  return bgImages;
}

const getFf = () => {
  let ffImages = [];
  for (let index = 1; index <= 104; index++) {
    ffImages.push({ img: `Images/Fotografens favoriter/ff${index}.jpg`, title: '' })
  }
  return ffImages;
}

const getP = () => {
  let pImages = [];
  for (let index = 1; index <= 27; index++) {
    pImages.push({ img: `Images/Polaroidbilder/p${index}.png`, title: '' })
  }
  return pImages;
}

const allImages = [...getAf(), ...getBg(), ...getFf(), ...getP()];

const images = [
  allImages,
  getFf(),
  getAf(),
  getBg(),
  getP()
];

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const imgStyle = {
  "&:hover": {
    cursor: 'pointer',
    filter: 'brightness(75%)',
  }
};

function App() {
  const [navigation, setNavigation] = useState(0);
  const [currentImages, setCurrentImages] = useState(images[navigation]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  const handleSelection = useCallback(
    (event) => {
      if (!open) return;

      const { key } = event;
      const arrowRightPressed = key === "ArrowRight";
      const arrowLeftPressed = key === "ArrowLeft";
      const escapePressed = key === "Escape" || key === "Esc";

      const searchDataLength = currentImages.length - 1;

      if (arrowLeftPressed) {
        event.preventDefault();
        setActiveIndex((currentIndex) =>
          currentIndex - 1 >= 0 ? currentIndex - 1 : searchDataLength
        );
      } else if (arrowRightPressed) {
        event.preventDefault();
        setActiveIndex((currentIndex) =>
          currentIndex + 1 <= searchDataLength ? currentIndex + 1 : 0
        );
      } else if (escapePressed) {
        event.preventDefault();
        setActiveIndex(1);
        setOpen(false);
      }
    },
    [open, currentImages]
  );

  useEffect(() => {
    setCurrentImages(images[navigation]);
  }, [navigation]);

  useEffect(() => {
    document.addEventListener("keydown", handleSelection);

    return () => {
      document.removeEventListener("keydown", handleSelection);
    };
  }, [handleSelection]);

  const handleOpen = (index) => {
    setActiveIndex(index)
    setOpen(true)
  };

  const handleClose = () => setOpen(false);

  const CardWithClick = useCallback(
    props => <Box sx={imgStyle}><img
      src={props.data.img}
      alt={props.data.title}
      width='100%'
      onClick={() => handleOpen(props.index)}
    /></Box>,
    []
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        sx={modalStyle}
      >
        <img src={currentImages[activeIndex].img} alt='' style={{ maxHeight: "90%", maxWidth: "90%" }} />
      </Modal>
      <Box sx={{ padding: '8px' }}>
        <Masonry
          key={navigation}
          id={navigation}
          itemHeightEstimate={800}
          itemKey={(data) => `$${data?.img}_${navigation}`}
          items={currentImages}
          columnGutter={8}
          rowGutter={8}
          maxColumnCount={4}
          render={CardWithClick}
        />
      </Box>
      <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            {['Alla bilder', 'Fotografens favoriter', 'Alla fotografens bilder', 'Gästernas bilder', 'Polaroidbilder'].map((text, index) => (
              <ListItem key={index} disablePadding sx={{ backgroundColor: navigation === index ? 'Gainsboro' : 'transparent' }}>
                <ListItemButton onClick={() => {
                  setActiveIndex(0);
                  setNavigation(index);
                  window.scrollTo(0, 0)
                }}>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <p style={{ padding: '20px', fontSize: '20px' }}>Tusen tack Ruth för alla fina bilder!</p>
        </Box>
      </Drawer>
      <IconButton sx={{ position: 'fixed', top: 0, right: 0, backgroundColor: 'white', margin: '10px' }} onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
    </>
  );
}

export default App;
